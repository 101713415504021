/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-12-07",
    versionChannel: "nightly",
    buildDate: "2023-12-07T00:15:04.300Z",
    commitHash: "41ddb316a2b0a91c0d32e8f54eb0f020be42831f",
};
